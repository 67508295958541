import { Field } from "formik"
import * as React from "react"
import Autocomplete from "react-autocomplete"
import Tooltip from "react-bootstrap/Tooltip"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import informationIcon from "../images/icon-information.png"
import { useState, useEffect } from "react"

const MgcAutoComplete = ({
  name,
  placeholder,
  errors,
  dirty,
  hidden,
  onChange,
  labelField,
  valueField,
  items,
  setFieldValue,
  tooltip,
  onSelect,
  prefix,
  withoutOffset,
  initValue,
  resetInitValue,
  values,
  disabled,
  classe,
}) => {
  const fieldName = prefix ? prefix + name : name

  useEffect(() => {
    if (initValue) {
      setValueAutocomplete(initValue)
      if (resetInitValue) {
        resetInitValue()
      }
    }
  })

  const [valueAutocomplete, setValueAutocomplete] = useState("")

  return (
    <div className="form-row" hidden={hidden}>
      {withoutOffset ? (
        ""
      ) : (
        <div className="col-1 text-right">
          {tooltip && (
            <OverlayTrigger
              overlay={
                <Tooltip id="title-tooltip" placement={"right"}>
                  <p>{tooltip}</p>
                </Tooltip>
              }
            >
              <img src={informationIcon} className="icon-info-ael" />
              {/*<span className="fa-exclamation-circle fa-2x fa" title="" />*/}
            </OverlayTrigger>
          )}
        </div>
      )}
      <div
        className={
          withoutOffset ? "col-11 form-group " + classe : "col-10 form-group"
        }
      >
        <Field
          type="text"
          name={name}
          className={"form-control "}
          placeholder={placeholder}
          hidden={true}
        />
        <Autocomplete
          id={fieldName}
          getItemValue={item => item[labelField]}
          items={items}
          renderItem={(item, isHighlighted) => (
            <div
              style={{ background: isHighlighted ? "lightgray" : "white" }}
              key={item.id}
            >
              {item[labelField]}
            </div>
          )}
          onChange={e => {
            setFieldValue(`${fieldName}Autocomplete`, e.target.value)
            setValueAutocomplete(e.target.value)
            if (!e.target.value) {
              setFieldValue(fieldName, e.target.value)
            }
            onChange(e.target.value)
          }}
          inputProps={{
            placeholder: placeholder,
            type: "text",
            className: "form-control",
            name: `${fieldName}Autocomplete`,
            id: `${fieldName}Autocomplete`,
            value: valueAutocomplete,
            disabled: disabled,
          }}
          onSelect={value => {
            setFieldValue(fieldName, value)
            setFieldValue(`${fieldName}Autocomplete`, value)
            setValueAutocomplete(value)
            if (onSelect) {
              onSelect(value)
            }
          }}
          value={valueAutocomplete}
          wrapperStyle={{
            width: "100%",
          }}
          renderMenu={children => (
            <div
              className="menu"
              style={{
                zIndex: 9999,
                border: "1px solid #ced4da",
                borderRadius: "0.25rem",
              }}
            >
              {children}
            </div>
          )}
        />
      </div>
      <div className="col-1">
        <span
          className={`${
            errors[name] || !dirty
              ? "fa-times text-danger"
              : "fa-check text-success"
          } fa-2x fa`}
        />
      </div>
    </div>
  )
}

export default MgcAutoComplete
