import * as React from "react"
import AelStep0 from "./aelStep0"
import AelStep6 from "./aelStep6"
import AelTdb from "./ael/aelTdb"
import AelWizardV2 from "./ael/v2/aelWizardV2"
import AelStep2V2 from "./ael/v2/aelStep2V2"
import AelStep1V2 from "./ael/v2/aelStep1V2"
import AelStep3V2 from "./ael/v2/aelStep3V2"
import AelStep5V2 from "./ael/v2/aelStep5V2"
import AelStep4V2 from "./ael/v2/aelStep4V2"

class AelV2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = { waitingBackStep2: false }
  }
  waitingBackStep2 = bool => {
    this.setState({ waitingBackStep2: bool })
  }
  render() {
    const { search, onAelFinalized } = this.props
    const initialValues = {
      conjointCivilite: "no",
      enfants: [],
      coordonneesBancaires: {},
      preference: {},
      identite: {
        recto: { content: "", contentType: "", size: 0 },
        verso: { content: "", contentType: "", size: 0 },
      },
      justificatifTns: {
        recto: { content: "", contentType: "", size: 0 },
        verso: { content: "", contentType: "", size: 0 },
      },
      radiation: {
        recto: { content: "", contentType: "", size: 0 },
        verso: { content: "", contentType: "", size: 0 },
      },
      justificatifFonctionPublique: {
        recto: { content: "", contentType: "", size: 0 },
        verso: { content: "", contentType: "", size: 0 },
      },
      autreDocument: {
        recto: { content: "", contentType: "", size: 0 },
        verso: { content: "", contentType: "", size: 0 },
      },
      precedentContrat: {
        recto: { content: "", contentType: "", size: 0 },
        verso: { content: "", contentType: "", size: 0 },
      },
    }
    return (
      <AelWizardV2
        initialValues={initialValues}
        search={search}
        waitingBackStep2={this.waitingBackStep2}
        waitingBackStep2Value={this.state.waitingBackStep2}
      >
        <AelStep0 />
        <AelStep1V2 />
        <AelStep2V2 waitingBackStep2={this.waitingBackStep2} />
        <AelStep3V2 />
        <AelStep4V2 />
        <AelStep5V2 />
        <AelStep6 onAelFinalized={onAelFinalized} />
        <AelTdb />
      </AelWizardV2>
    )
  }
}

export default AelV2
