import * as React from "react"
import { Field } from "formik"
import Axios from "axios"
import MaskedInput from "react-text-mask"
import { maskPostalCode } from "../services/global/fieldMasks"

class MgcCpVille extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cities: [],
      test: true
    }
  }

  handleCpChange(value) {
    const { nameVille, setFieldValue, prefix } = this.props
    const fieldNameVille = prefix ? prefix + nameVille : nameVille
    if (value && value.length && value.length === 5) {
      let URL = `${
        process.env.GATSBY_MGCGESTION_API_URL
      }/public/code-postals/code/${value}`
/*      URL = `http://localhost:8080/api/public/code-postals/code/${value}`*/
       Axios.get(URL).then(result => {
        this.setState({ cities: result.data })
        if (this.state.cities.length > 0) {
          setFieldValue(fieldNameVille, this.state.cities[0].commune)
        } else {
          setFieldValue(fieldNameVille, null)
        }
      })
    }
  }

  componentDidMount() {
    const { nameCP, values, prefix ,initValue } = this.props
    const fieldNameCp = prefix ? prefix + nameCP : nameCP
    if (values[fieldNameCp]) {
      this.handleCpChange(values[fieldNameCp])
    }
    if(initValue){
      if(initValue.CodePostalNaissance){
        this.handleCpChange(initValue.CodePostalNaissance)
      }else{
        this.handleCpChange(initValue)
      }

    }
  }

  render() {
    const {
      nameCP,
      nameVille,
      errors,
      dirty,
      setFieldValue,
      hidden,
      values,
      placeholderCp,
      prefix,
      withoutOffset,
      disabled
    } = this.props
    const fieldNameCp = prefix ? prefix + nameCP : nameCP
    const fieldNameVille = prefix ? prefix + nameVille : nameVille
    let citiesOptions = this.state.cities.map(c => (
      <option value={c.commune} key={c.id}>
        {c.commune}
      </option>
    ))
    return (
      <div className="form-row" hidden={hidden}>
        <div
          className={
            withoutOffset ? "col-5 form-group" : "col-4 offset-1 form-group"
          }
        >
          <Field
            name={fieldNameCp}
            render={({ field }) => {
              return (
                <MaskedInput
                  mask={maskPostalCode}
                  {...field}
                  className="form-control"
                  placeholder={placeholderCp}
                  guide={false}
                  id={fieldNameCp}
                  disabled={disabled}
                  onChange={e => {
                    this.handleCpChange(e.target.value)
                    setFieldValue(fieldNameCp, e.target.value)
                  }}
                />
              )
            }}
          />
        </div>
        <div className="col-6  form-group">
          <Field
            component="select"
            name={fieldNameVille}
            className="form-control adr-ville"
            value={values[nameVille]}
            id={fieldNameVille}
            disabled={disabled}
          >
            <option value="">- Ville -</option>
            {citiesOptions}
          </Field>
        </div>
        <div className="col-1">
          <span
            className={`${
              errors[nameCP] || errors[nameVille] || !dirty
                ? "fa fa-times text-danger"
                : "fa fa-check text-success"
            } fa-2x fa`}
          />
        </div>
      </div>
    )
  }
}

export default MgcCpVille
