import { Field } from "formik"
import * as React from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import classNames from "classnames"
import informationIcon from "../images/icon-information.png"

const MgcTextField = ({
  name,
  placeholder,
  errors,
  dirty,
  hidden,
  tooltip,
  prefix,
  withoutOffset,
  type,
  disabled,
  maxLength,
  onChange,
  displayError
}) => {
  const fieldName = prefix ? `${prefix}${name}` : name
  const error = errors[name] || !dirty

  const formGroupClassNames = classNames("form-group", {
    "col-10 offset-1": !tooltip && !withoutOffset,
    "col-11": withoutOffset && !tooltip,
    "col-10": tooltip,
  })
  const checkerClassNames = classNames("fa fa-2x", {
    "fa-times text-danger": error,
    "fa-check text-success": !error,
  })

  return (
    <div className="form-row" hidden={hidden}>
      {tooltip && (
        <div className="col-1 text-right">
          <OverlayTrigger
            overlay={
              <Tooltip id="title-tooltip" placement={"right"}>
                <p>{tooltip}</p>
              </Tooltip>
            }
          >
            <img src={informationIcon} className="icon-info-ael" />
          </OverlayTrigger>
        </div>
      )}
      <div className={formGroupClassNames}>
        <Field
          type={type ? type : "text"}
          name={fieldName}
          className="form-control"
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={onChange}
        >
        </Field>
        {displayError && (<div className="invalid-feedback-line">{error}</div>)
      }
      </div>

      <div className="col-1">
        <span className={checkerClassNames} />
      </div>

    </div>
  )
}

MgcTextField.defaultProps = {
  type: "text",
  maxLength: 524288
}

export default MgcTextField
