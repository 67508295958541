import { Field } from "formik"
import * as React from "react"
import classNames from "classnames"

const MgcSelectField = ({
  name,
  errors,
  dirty,
  table,
  firstOption,
  valueField,
  labelField,
  withOffset,
  onChange,
  setFieldValue,
  hidden,
  deathBenefit,
  disabled,
  prefix,
  assusranceMalUnselected,
  onBlur
}) => {
  const classes = classNames("form-group", {
    "col-10 offset-1": withOffset,
    "col-11": !withOffset || deathBenefit,
  })
  const Fieldclass = classNames("form-control", {
    "assuranceMal-unselected": assusranceMalUnselected,
  })
  const fieldName = prefix ? `${prefix}${name}` : name
  return (
    <div className="form-row" hidden={hidden}>
      <div className={classes}>
        <Field
          name={fieldName}
          component="select"
          className={Fieldclass}
          disabled={disabled}
          onChange={e => {
            setFieldValue(fieldName, e.target.value)
            if (onChange) {
              onChange(e.target.value)
            }
          }}
          onBlur={() => {
            if (onBlur) {
              onBlur()
            }
          }}
        >
          <option key="first" value="">
            {firstOption}
          </option>
          {table &&
            table.map(e => {
              return (
                <option
                  key={valueField ? e[valueField] : e.id}
                  value={valueField ? e[valueField] : e.id}
                >
                  {labelField ? e[labelField] : e.libelle}
                </option>
              )
            })}
        </Field>
      </div>
      <div className="col-1">
        <span
          className={`${errors[name] || !dirty
              ? "fa fa-times text-danger"
              : "fa fa-check text-success"
            } fa-2x fa`}
        />
      </div>
    </div>
  )
}

export default MgcSelectField
